@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base{
    body{
        @apply font-inter font-normal overflow-x-hidden text-black-1
    }
}

@layer utilities {
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }
  
    .no-scrollbar {
      -ms-overflow-style: none;  
      scrollbar-width: none;     
    }
  }